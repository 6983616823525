<template>
  <v-card>
    <v-card-title>
      <v-icon>
        {{ icons.mdiBarcode }}
      </v-icon>
      <span class="me-3">&nbsp; Boletos</span>
      <v-spacer></v-spacer>
      <v-chip :color="`${statusBoletoColor}`" class="ms-0 me-3 mt-5" label small>
        <span class="text-xs text--white cursor-pointer">{{ statusBoleto }}</span>
      </v-chip>
      <v-checkbox v-if="!this.statusBoletoOk" v-model="checkMostrarBoletosVencidos" label="Mostrar boletos vencidos"
        color="primary" :value="true" hide-details @change="onCheckboxBoletosChange"></v-checkbox>
    </v-card-title>

    <v-data-table :headers="headers" :items="this.boletosAMostrar" item-key="id" class="table-rounded"
      hide-default-footer disable-sort fixed-header no-data-text="Nenhum boleto encontrado"
      no-results-text="Nenhum boleto encontrado">
      <!-- 
      <template #[`item.id`]="{item}">
        <v-icon>
              {{ icons.mdiBarcodeScan }}
            </v-icon>
        <div class="d-flex flex-column">
          <small>{{ item.post }}</small>
        </div>
      </template>
name -->

      <!-- Valor -->
      <template #[`item.valor`]="{ item }">
        <v-chip small color="red" outlined class="font-weight-medium">
          R$ {{ formatPrice(item.valor) }}
        </v-chip>
      </template>
      <!-- status -->
      <template #[`item.status`]="{ item }">
        <v-chip small :color="statusColor[item.status]" class="font-weight-medium">
          {{ status[item.status] }}
        </v-chip>
      </template>

      <!-- Ação  -->
      <template #[`item.acoes`]="{ item }">
        <v-dialog transition="dialog-top-transition" :retain-focus="false" v-model="dialogs[item.index]"
          max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon color="white" dark v-bind="attrs" v-on="on" v-if="isVencimentoValido(item.vencimento)">
              <v-icon color="blue-grey">
                {{ icons.mdiBarcodeScan }}
              </v-icon>
            </v-btn>
            <v-chip small v-else color="red" dark>
              Boleto vencido entre em contato com a incorporadora.
            </v-chip>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Dados do boleto #{{ item.acoes.seuNumero }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-card elevation="15" class="mx-auto">
                    <template slot="progress">
                      <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
                    </template>
                    <v-card-title>
                      <strong
                        class="blue-grey--text darken-1 accent-1 text--lighten-1">{{ item.acoes.descricaoObra }}</strong>
                    </v-card-title>

                    <v-card-text>
                      <v-row align="center" class="mx-0">

                        <div class="grey--text ">
                          <strong
                            class="blue-grey--text darken-1 darken-2 text--lighten-1">{{ item.acoes.descricaoEmpresa }}</strong>
                        </div>
                      </v-row>

                      <v-row align="center" class="mx-0">

                        <div class="my-4 grey--text ">
                          <strong class="blue-grey--text text--lighten-1">COD. BANCO {{ item.acoes.codBanco }}</strong>
                        </div>
                      </v-row>

                      <v-row align="center" class="mx-0">
                        <div class="grey--text ">
                          <strong class="blue-grey--text text--lighten-1">BANCO {{ item.acoes.nomeBanco }}</strong>
                        </div>
                      </v-row>

                      <v-divider class="my-4 mx-4"></v-divider>
                      <div class="my-4">
                        <strong class="blue-grey--text text--lighten-1">Emissão
                          {{ moment(String(item.acoes.dataEmissao)).format('DD/MM/YYYY') }}</strong>
                      </div>

                      <div class="my-4">
                        <strong class="red--text darken-1 text--lighten-1">Vencimento
                          {{ moment(String(item.acoes.dataVencimento)).format('DD/MM/YYYY') }}</strong>
                      </div>

                      <v-row>
                        <v-col cols="12">
                          <p class="blue-grey--text darken-1">Código de barras</p>
                        </v-col>
                      </v-row>

                      <v-row class="d-flex justify-center">
                        <div>
                          <strong class="blue-grey--text text--lighten-1">{{ item.acoes.linhaDigitavel }}</strong>
                        </div>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-row class="d-flex justify-center mt-3">
                        <v-btn color="red darken-1" text @click="copy(item.acoes.linhaDigitavel)">
                          Copiar código de barras
                        </v-btn>
                        <v-btn color="green" text @click="gerarPdfBoleto(item.acoes.codBanco, item.acoes.seuNumero)">
                          Gerar PDF
                        </v-btn>
                      </v-row>
                    </v-card-actions>
                    <div class="my-4 ml-5">
                      <small>* Se já pagou desconsiderar, a baixa do boleto pode demorar 3 dias úteis.</small>
                    </div>
                  </v-card>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-2 white--text" @click="closeDialogs(item.index)">
                Fechar
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-snackbar v-model="snackbar" :timeout="-1">
            {{ toastText }}

            <template v-slot:action="{ attrs }">
              <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
                Fechar
              </v-btn>
            </template>
          </v-snackbar>
        </v-dialog>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-row class="ml-3 mt-2 py-3">
      <span style="font-size: 12px; color: red;">* Disponível apenas os boletos referente ao seu carnê vigente.</span>
    </v-row>
  </v-card>
</template>

<script>
import { mdiBarcode, mdiBarcodeScan, mdiEyeOutline } from '@mdi/js'
import Cookie from 'js-cookie'
import moment from 'moment'
import UsuarioStore from './../../store/Usuario'
import Strings from '@/common/strings'

export default {
  props: {
    boletos: [],
    boletosNaoVencidos: []
  },
  mounted: function () {
    this.momentF = moment

    this.boletosAMostrar = this.boletos

    var dialogsShow = []


    if (this.boletos.length == this.boletosNaoVencidos.length) {
      this.statusBoletoColor = 'green'
      this.statusBoleto = 'Parabéns, você está em dias com seus boletos'
    }
    else {
      this.statusBoletoOk = false
      this.statusBoletoColor = 'red'
      this.statusBoleto = 'Você possui alguns boletos vencidos'
    }

    this.boletos.map((val, index) => {
      dialogsShow[index] = false
    })

    this.dialogs = dialogsShow
  },
  data() {
    return {
      checkMostrarBoletosVencidos: true,
      boletosAMostrar: [],
      dialogs: [],
      statusBoleto: '',
      statusBoletoColor: '',
      statusBoletoOk: true
    }
  },
  methods: {
    isVencimentoValido(vencimento) {
      const dataAtual = new Date();
      const dataVencimento = new Date(vencimento);
      return dataVencimento > dataAtual;
    },
    onCheckboxBoletosChange(value) {

      if (value == null)
        this.boletosAMostrar = this.boletosNaoVencidos
      else
        this.boletosAMostrar = this.boletos

    },
    closeDialogs(index) {
      this.$set(this.dialogs, index, false)
    },
    copy(cod) {
      navigator.clipboard.writeText(cod)
      this.toastText = 'Copiado '
      this.snackbar = true
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },

    gerarPdfBoleto(cod_banco, seu_numero) {

      var payload = {
        "cod_banco": cod_banco,
        "seu_numero": seu_numero.toString()
      }

      this.$loading(true)

      UsuarioStore.gerarPdfBoleto(payload, Strings.imobiliariaId).then(result => {

        this.$router.push({ name: 'pdf-viewer', params: { pdfBase64: result.data.toString() } })

      }).catch(error => {

        if (error.response.status === 401) {
          Cookie.remove(Strings.cookie_token)
          Cookie.remove(Strings.cookie_username)
          this.$router.push('/pages/login')
        }

        console.log(error)

        this.toastText = error.response.data
        this.snackbar = true
        this.$loading(false)
      })

    }
  },
  setup() {

    const toastText = ''
    const snackbar = false
    const momentF = Object

    const statusColor = {
      /* eslint-disable key-spacing */
      1: 'success',
      2: 'red',
      3: 'info'
      /* eslint-enable key-spacing */
    }

    return {
      headers: [
        { text: 'Vencimento', value: 'vencimento' },
        { text: 'Empreendimento', value: 'empreendimento' },
        { text: 'Obra', value: 'obra' },
        { text: 'Valor', value: 'valor' },
        { text: 'Status', value: 'status' },
        { text: 'Ações', value: 'acoes' },
      ],
      status: {
        1: 'Normal',
        2: '*******',
        3: 'A vencer',
      },
      statusColor,

      // icons
      icons: {
        mdiBarcode,
        mdiBarcodeScan,
        mdiEyeOutline
      },
      momentF,
      toastText,
      snackbar,
    }
  },
}
</script>
<style scoped>
.btn-bol {
  border-radius: 40px !important;
  width: 3rem !important;
  background: rgb(0, 136, 255);
  height: 1.6rem !important;
}
</style>